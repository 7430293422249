import _requireFromString from "require-from-string";
import _readFile from "./readFile";
var exports = {};
var requireFromString = _requireFromString;
var readFile = _readFile;

exports = function (filepath) {
  return readFile(filepath).then(function (content) {
    if (!content) return null;
    return {
      config: requireFromString(content, filepath),
      filepath: filepath
    };
  });
};

export default exports;