import _parseJson from "parse-json";
var exports = {};
var parseJson = _parseJson;

exports = function (json, filepath) {
  try {
    return parseJson(json);
  } catch (err) {
    err.message = "JSON Error in " + filepath + ":\n" + err.message;
    throw err;
  }
};

export default exports;