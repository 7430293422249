import _path from "path";
import _osHomedir from "os-homedir";
import _minimist from "minimist";
import _objectAssign from "object-assign";
import _createExplorer from "./lib/createExplorer";
import _process from "process";
var exports = {};
var process = _process;
var path = _path;
var oshomedir = _osHomedir;
var minimist = _minimist;
var assign = _objectAssign;
var createExplorer = _createExplorer;
var parsedCliArgs = minimist(process.argv);

exports = function (moduleName, options) {
  options = assign({
    packageProp: moduleName,
    rc: "." + moduleName + "rc",
    js: moduleName + ".config.js",
    argv: "config",
    rcStrictJson: false,
    stopDir: oshomedir(),
    cache: true
  }, options);

  if (options.argv && parsedCliArgs[options.argv]) {
    options.configPath = path.resolve(parsedCliArgs[options.argv]);
  }

  return createExplorer(options);
};

export default exports;