import _path from "path";
import _readFile from "./readFile";
import _parseJson from "./parseJson";
var exports = {};
var path = _path;
var readFile = _readFile;
var parseJson = _parseJson;

exports = function (packageDir, options) {
  var packagePath = path.join(packageDir, "package.json");
  return readFile(packagePath).then(function (content) {
    if (!content) return null;
    var parsedContent = parseJson(content, packagePath);
    var packagePropValue = parsedContent[options.packageProp];
    if (!packagePropValue) return null;
    return {
      config: packagePropValue,
      filepath: packagePath
    };
  });
};

export default exports;